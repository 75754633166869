import clsx from "clsx";
import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Button } from "@happy-rabbit/component-library";

const QuantitySelector = props => {
  const {
    disabled,
    onChange,
    quantity,
  } = props;

  return (
    <div className="flex justify-start items-center">
      <Button
        className={clsx("border border-depalmaBlack rounded-full p-1 disabled:text-gray-500 disabled:border-gray-500", {"hover:bg-depalmaWhite": !disabled})}
        theme="none"
        height={null}
        disabled={disabled}
        onClick={() => !disabled && quantity > 0 && onChange(quantity - 1, -1)}
      >
        <FaMinus className="text-xs" />
      </Button>

      <div className="w-8 text-center">
        {quantity}
      </div>
      
      <Button
        className={clsx("border border-depalmaBlack rounded-full p-1 disabled:text-gray-500 disabled:border-gray-500", {"hover:bg-depalmaWhite": !disabled})}
        theme="none"
        height={null}
        disabled={disabled}
        onClick={() => !disabled && onChange(quantity + 1, 1)}
      >
        <FaPlus className="text-xs" />
      </Button>
    </div>
  );
};

export default QuantitySelector;
