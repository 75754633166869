import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import CountrySelect, { buildCountryOption } from "./CountrySelect";

//import { displayErrors } from './errors';
const displayErrors = () => null;

const FieldSelect = (props) => {
  const {
    attribute,
    defaultValue,
    description = null,
    disabled = false,
    errors = {},
    isMulti = false,
    label,
    onChange = null,
    options = [],
    placeholder = label,
    register,
    setValue,
    ...rest
  } = props;

  const [values, setReactSelect] = useState({
    selectedOption: defaultValue,
  });

  useEffect(() => {
    if (isMulti) {
      setReactSelect({ selectedOption: options.filter(o => defaultValue.indexOf(o.value) >= 0) || undefined });
    } else {
      setReactSelect({ selectedOption: options.find(o => defaultValue === o.value) || null });
    }
  }, [isMulti, options, defaultValue]);

  const handleMultiChange = selectedOption => {
    if (isMulti) {
      setValue && setValue(attribute, selectedOption.map(o => o.value));
    } else {
      setValue && setValue(attribute, selectedOption.value);
    }
    setReactSelect({ selectedOption });

    if (onChange) {
      onChange(selectedOption);
    }
  };

  useEffect(() => {
    register && register({ name: attribute, value: defaultValue });
  }, [attribute, defaultValue, register]);

  return (
    <div className="mb-4">
      <label className="block mb-2">
        <span className="text-xs">{label}</span>
        <Select
          {...rest}
          isDisabled={disabled}
          isMulti={isMulti}  
          name={attribute}
          onChange={handleMultiChange}
          options={options}
          placeholder={placeholder}
          styles={{
            control: (styles, { isFocused }) => ({
              ...styles,
              borderColor: !errors[attribute] ? 'none' : 'red',
              borderRadius: 'none',
              boxShadow: isFocused ? '0 0 0 3px rgba(66, 153, 225, 0.5)' : '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
              outline: 'none',
              '&:hover': {},
            }),
          }}
          value={values.selectedOption}
        />
      </label>

      {description ? <p className="font-normal text-xs">{description}</p> : null}

      {displayErrors(errors, attribute)}
    </div>
  );
};

export default FieldSelect;

export {
  buildCountryOption,
  CountrySelect,
};
