"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
var _ClientContext = _interopRequireWildcard(require("./ClientContext"));
exports.default = _ClientContext.default;
exports.useClientContext = _ClientContext.useClientContext;
exports.withStoreContext = _ClientContext.withStoreContext;
var _PageSections = _interopRequireDefault(require("./components/PageSections"));
exports.PageSections = _PageSections.default;
var _CMSContext = _interopRequireWildcard(require("./contexts/CMSContext"));
exports.CMSProvider = _CMSContext.default;
exports.useCMSContext = _CMSContext.useCMSContext;
var _ThemesContext = _interopRequireWildcard(require("./contexts/ThemesContext"));
exports.ThemesProvider = _ThemesContext.default;
exports.useThemesContext = _ThemesContext.useThemesContext;
var _Locale = require("./components/Locale");
exports.LocaleBlockContent = _Locale.LocaleBlockContent;
exports.LocaleString = _Locale.LocaleString;
exports.LocaleText = _Locale.LocaleText;
exports.getLocaleValue = _Locale.getLocaleValue;
exports.combineLocale = _Locale.combineLocale;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }