import React from "react";
import { graphql, StaticQuery } from 'gatsby';

import * as fragments from "../nodes/sanity/graphqlFragments";

const withNavs = (DecoratedComponent) => {
  return props =>  (
    <StaticQuery
      query={graphql`
        query SanityNavQuery {
          allSanityNav {
            edges {
              node {

                title {
                  ...SanityLocaleStringFragment
                }
                navId
                navItems {
                  ... on SanityNavItemsPage {
                    ...SanityNavItemsPageFragment
                    childItems {
                      ... on SanityNavItemsPage {
                        ...SanityNavItemsPageFragment
                      }
                      ... on SanityNavItemsUrl {
                        ...SanityNavItemsUrlFragment
                      }
                    }
                  }
                  ... on SanityNavItemsUrl {
                    ...SanityNavItemsUrlFragment
                    childItems {
                      ... on SanityNavItemsPage {
                        ...SanityNavItemsPageFragment
                      }
                      ... on SanityNavItemsUrl {
                        ...SanityNavItemsUrlFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <DecoratedComponent {...data} {...props} />}
    />
  );
};

export default withNavs;
