"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.imageRatioForBoxRatio = imageRatioForBoxRatio;
exports.paddingClasses = paddingClasses;
exports.placementClasses = placementClasses;
exports.responsiveClasses = responsiveClasses;
exports.responsiveSrcSet = responsiveSrcSet;
var _extends3 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var placeToText = {
  start: "left",
  center: "center",
  end: "right"
};
function imageRatioForBoxRatio(boxRatio) {
  if (!boxRatio || Object.keys(boxRatio).length === 0) return null;
  var _reduce = ["xs", "sm", "md", "lg", "xl"].reduce(function (_ref, bp) {
      var acc = _ref[0],
        propagatedRatio = _ref[1],
        lastBP = _ref[2];
      // Check if boxRatio has a ratio for current breakpoint
      if (boxRatio[bp]) {
        // If it does, then we first check if there was a propagated ratio that should be stored
        if (propagatedRatio) {
          var _extends2;
          return [(0, _extends3.default)({}, acc, (_extends2 = {}, _extends2[lastBP] = propagatedRatio, _extends2)), boxRatio[bp], bp];
        }
        return [acc, boxRatio[bp], bp];
      }
      return [acc, propagatedRatio, bp];
    }, [{}, null, null]),
    convImageRatio = _reduce[0],
    lastRatio = _reduce[1];

  // Return the converted image ratio object and include 
  //the lastRatio that was found as the default (xl) ratio.
  return (0, _extends3.default)({}, convImageRatio, {
    xl: lastRatio
  });
}
function responsiveClasses(object, options) {
  var _ref2 = options || {},
    _ref2$defaultClasses = _ref2.defaultClasses,
    defaultClasses = _ref2$defaultClasses === void 0 ? [] : _ref2$defaultClasses,
    _ref2$prefix = _ref2.prefix,
    prefix = _ref2$prefix === void 0 ? "" : _ref2$prefix;
  if (Object.keys(object || {}).length === 0) return defaultClasses;
  return ["xs", "sm", "md", "lg", "xl"].reduce(function (acc, size) {
    var cssBP = size === "xs" ? "" : size + ":";
    if (object[size]) {
      return [].concat(acc, ["" + cssBP + prefix + object[size]]);
    }
    return acc;
  }, []);
}
function paddingClasses(padding, defaultClasses) {
  if (defaultClasses === void 0) {
    defaultClasses = [];
  }
  if (!padding) return defaultClasses;
  return ["xs", "sm", "md", "lg", "xl"].reduce(function (acc, size) {
    if (!padding[size]) {
      return acc;
    } else {
      var cssBP = size === "xs" ? "" : size + ":";
      var res = ["top", "bottom", "left", "right"].reduce(function (acc2, dir) {
        if (padding[size][dir]) {
          return [].concat(acc2, [cssBP + "p" + dir[0] + padding[size][dir]]);
        }
        return acc2;
      }, []);
      return [].concat(acc, res);
    }
  }, []);
}
function placementClasses(respPlacement, defaultClasses) {
  if (defaultClasses === void 0) {
    defaultClasses = [];
  }
  if (!respPlacement) return defaultClasses;
  return ["xs", "sm", "md", "lg", "xl"].reduce(function (acc, size) {
    var h = size + "Horizontal";
    var v = size + "Vertical";
    var prefix = size === "xs" ? "" : size + ":";
    return [].concat(acc, respPlacement[h] ? [prefix + "items-" + respPlacement[h] + " " + prefix + "text-" + placeToText[respPlacement[h]]] : [], respPlacement[v] ? [prefix + "justify-" + respPlacement[v]] : []);
  }, []);
}
function responsiveSrcSet(imageBuilder, imageRatio, colsPerBreakpoint) {
  if (colsPerBreakpoint === void 0) {
    colsPerBreakpoint = {};
  }
  var breakpoints = {
    xl: 2560,
    lg: 1280,
    md: 1024,
    sm: 768,
    xs: 640
  };
  var ratios = {
    "3:4": 3 / 4,
    "square": 1,
    "1:1": 1,
    "4:3": 4 / 3,
    "16:9": 16 / 9,
    "panorama": 2,
    "2:1": 2
  };
  var currentRatio = null;
  var srcsets = Object.keys(breakpoints).map(function (r) {
    var scale = 1 / (colsPerBreakpoint[r] || 1);
    currentRatio = imageRatio && imageRatio[r] || currentRatio;
    var bp = breakpoints[r];
    var ratio = ratios[currentRatio];

    // Base Url without limiting to height based on ratio.
    var baseUrl = imageBuilder.width(parseInt(bp * scale));
    return [
    // Apply specific height only if there is a ratio that applies to this breakpoint or larger
    currentRatio ? baseUrl.height(parseInt(bp / ratio * scale)).url() : baseUrl.url(), bp, scale];
  }).filter(Boolean);
  var srcSet = srcsets.map(function (_ref3) {
    var url = _ref3[0],
      w = _ref3[1];
    return url + " " + w + "w";
  }).join(", ");
  var largestScale = srcsets[0][2];
  return {
    src: imageBuilder.maxWidth(parseInt(2560 * largestScale)).url(),
    srcSet: srcSet
  };
}