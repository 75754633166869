import React from "react";
import { graphql, StaticQuery } from 'gatsby';

const withBestsellers = (DecoratedComponent) => {
  return props =>  (
    <StaticQuery
      query={graphql`
        query  {
          allSanityProductPageVariant (filter: { discontinued: { ne: true }, bestseller: { eq: true }, hidden: { ne: true } }) {
            edges {
              node {
                ...SanityProductPageVariantPreviewFragment
                bestseller
              }
            }
          }
        }
      `}
      render={data => <DecoratedComponent {...data} {...props} />}
    />
  );
};

export default withBestsellers;
