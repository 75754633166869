import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import axios from 'axios'

import { withStoreContext } from '../../plugins/gatsby-shopify-buy-plugin'

import { getLocal, storeLocal } from '../utils/local'
import { useCountryStateContext } from './CountryContext'

const updateCustomer = ({ id, phone, consentStatus, userType }) => {
  if (window?.location) {
    const url = new URL(
      '/.netlify/functions/shopifyCustomerUpdate',
      window.location,
    )
    url.searchParams.append('id', id)
    url.searchParams.append('phone', phone)
    url.searchParams.append('consentStatus', consentStatus)
    url.searchParams.append('userType', userType)
    return fetch(url)
      .then((res) => res.json())
      .catch((error) => {
        console.log(error)
        return ['error', null, error]
      })
  } else {
    return Promise.resolve(['error', null])
  }
}

const fetchCustomer = (email) => {
  if (window?.location) {
    const url = new URL(
      '/.netlify/functions/shopifyCustomerFetch',
      window.location,
    )
    url.searchParams.append('email', email)
    return fetch(url)
      .then((res) => res.json())
      .then((data) => {
        return ['done', data]
      })
      .catch((error) => {
        console.log(error)
        return ['error', null, error]
      })
  } else {
    return Promise.resolve(['error', null])
  }
}

const defaultCart = {
  cartRequest: false,
  checkout: { lineItems: [] },
  products: {},
  savedAt: null,
  shop: {},
  data: {},
  existingUser: {},
}

const SHOPPING_CART_KEY = 'DePalmaWorkwear-ShoppingCart'

const CartStateContext = createContext(null)

export const variantIdToGid = (id) => btoa(`gid://shopify/ProductVariant/${id}`)

export function variantLineItemFromCart(cart, variant_id) {
  return (
    (cart?.checkout?.lineItems || []).find(
      (li) => li.variant.id === variantIdToGid(variant_id),
    ) || null
  )
}

function addToExistingCustomAttributes(cart, customAttributes) {
  const addKeys = customAttributes.map((ca) => ca.key)
  const existingCustom = cart?.checkout?.customAttributes || []

  return [
    ...existingCustom
      .filter((ca) => addKeys.indexOf(ca.key) === -1)
      .map((ca) => ({ key: ca.key, value: ca.value })),
    ...customAttributes,
  ]
}

const CartProvider = (props) => {
  const {
    children,
    storeContext: { client },
  } = props

  const [cart, setCart] = useState(null)
  const { currencyCode, country } = useCountryStateContext()

  const getCheckoutId = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (cart?.checkout?.id) {
        resolve(cart.checkout.id)
      } else {
        client.checkout
          .create({ presentmentCurrencyCode: currencyCode })
          .then((checkout) => {
            let newCart
            setCart((c) => {
              newCart = { ...c, checkout }
              return newCart
            })
            storeLocal(`${SHOPPING_CART_KEY}-${country.slug.current}`, newCart)
            resolve(checkout.id)
          })
          .catch(reject)
      }
    })
  }, [cart, client, country, currencyCode])

  const setConsents = useCallback(
    ({ email, newsletterConsent }) => {
      const behaviour = getLocal('DePalmaWorkwear-bhvr')
      const userType = `User type: ${behaviour?.lastProfileSelected}`
      if (email !== cart?.checkout?.email || cart.existingUser === null) {
        fetchCustomer(email).then((res) => {
          if (
            res[0] === 'done' &&
            res[1].customers &&
            res[1].customers.length > 0
          ) {
            cart.existingUser['id'] = res[1].customers[0].id
            cart.existingUser['phone'] = res[1].customers[0].phone
            cart.existingUser['consent'] = newsletterConsent
            let newCart
            setCart(
              (c) =>
                (newCart = {
                  ...c,
                  existingUser: cart.existingUser,
                  savedAt: Date.now(),
                }),
            )
            storeLocal(`${SHOPPING_CART_KEY}-${country.slug.current}`, newCart)
            updateCustomer({
              id: cart?.existingUser?.id,
              phone: cart?.existingUser?.phone,
              consentStatus: newsletterConsent,
              userType: userType,
            })
          }
        })
      } else if (newsletterConsent !== cart?.existingUser?.consent) {
        cart.existingUser['consent'] = newsletterConsent
        let newCart
        setCart(
          (c) =>
            (newCart = {
              ...c,
              existingUser: cart.existingUser,
              savedAt: Date.now(),
            }),
        )
        storeLocal(`${SHOPPING_CART_KEY}-${country.slug.current}`, newCart)
        updateCustomer({
          id: cart?.existingUser?.id,
          phone: cart?.existingUser?.phone,
          consentStatus: newsletterConsent,
          userType: userType,
        })
      }
    },
    [cart],
  )

  const startRequest = useCallback(
    () =>
      setCart((c) => ({
        ...c,
        cartRequest: true,
        checkout: { ...c?.checkout, userErrors: [] },
      })),
    [],
  )

  const saveCart = useCallback(
    (checkout) => {
      if (country) {
        let newCart
        setCart(
          (c) =>
            (newCart = {
              ...c,
              checkout,
              cartRequest: false,
              savedAt: Date.now(),
            }),
        )
        storeLocal(`${SHOPPING_CART_KEY}-${country.slug.current}`, newCart)
      }
    },
    [cart, country],
  )

  const saveDataToCart = useCallback(
    (data) => {
      if (!country) return

      let newCart
      setCart((c) => (newCart = { ...c, data: { ...c?.data, ...data } }))
      storeLocal(`${SHOPPING_CART_KEY}-${country.slug.current}`, newCart)
    },
    [country],
  )

  const stopRequest = useCallback(
    (err) => {
      console.log(err)
      if (country) {
        if (
          err.message?.match &&
          (err.message?.match('Checkout is already completed.') ||
            err.message?.match('Checkout does not exist'))
        ) {
          // Reset to a new Cart
          setCart(defaultCart)
          storeLocal(
            `${SHOPPING_CART_KEY}-${country.slug.current}`,
            defaultCart,
          )
        } else {
          let newCart
          setCart(
            (c) =>
              (newCart = {
                ...c,
                cartRequest: false,
              }),
          )
          storeLocal(`${SHOPPING_CART_KEY}-${country.slug.current}`, newCart)
        }
      }
    },
    [cart, country],
  )

  const updateCart = useCallback(
    (fields) => {
      if (!cart) return

      // Do not do anything if a request is already in progress
      if (cart.cartRequest || !cart.checkout.id) return

      // First we flag that we are starting a request
      startRequest()

      client.checkout
        .updateAttributes(cart.checkout.id, fields)
        .then(saveCart)
        .catch(stopRequest)
    },
    [cart, client, saveCart, startRequest, stopRequest],
  )

  const updateContactDetails = useCallback(
    ({ email, newsletterConsent }) => {
      if (!email) return
      if (!cart) return
      if (!cart.checkout.id) return

      setConsents({ email, newsletterConsent })

      // Do not do anything if a request is already in progress
      if (cart.cartRequest) return
      // First we flag that we are starting a request
      startRequest()

      Promise.all([
        client.checkout.updateEmail(cart.checkout.id, email),
        client.checkout.updateAttributes(cart.checkout.id, {
          customAttributes: addToExistingCustomAttributes(cart, [
            {
              key: 'newsletterConsent',
              value: newsletterConsent ? 'true' : 'false',
            },
          ]),
        }),
      ])
        .then(([resUpdateEmail]) => {
          const mergeWith = resUpdateEmail.userErrors?.length
            ? { userErrors: resUpdateEmail.userErrors }
            : {}
          return client.checkout
            .fetch(cart.checkout.id)
            .then((res) => ({ ...res, ...mergeWith }))
        })
        .then(saveCart)
        .catch(stopRequest)
    },
    [cart, client, saveCart, startRequest, stopRequest],
  )

  const updateShippingAddress = useCallback(
    (shippingAddress) => {
      if (!cart) return

      // Do not do anything if a request is already in progress
      if (cart.cartRequest || !cart.checkout.id) return

      // First we flag that we are starting a request
      startRequest()

      const { vatNumber, ...addressData } = shippingAddress

      if (typeof vatNumber === 'undefined') {
        client.checkout
          .updateShippingAddress(cart.checkout.id, shippingAddress)
          .then(saveCart)
          .catch(stopRequest)
      } else {
        Promise.all([
          client.checkout.updateShippingAddress(cart.checkout.id, addressData),
          client.checkout.updateAttributes(cart.checkout.id, {
            customAttributes: addToExistingCustomAttributes(cart, [
              {
                key: 'vatNumber',
                value: vatNumber,
              },
            ]),
          }),
        ])
          .then(([resUpdateShippingAddress, resUpdateAttributes]) => {
            const mergeWith = resUpdateShippingAddress.userErrors?.length
              ? { userErrors: resUpdateShippingAddress.userErrors }
              : {}
            return client.checkout
              .fetch(cart.checkout.id)
              .then((res) => ({ ...res, ...mergeWith }))
          })
          .then(saveCart)
          .catch(stopRequest)
      }
    },
    [cart, client, saveCart, startRequest, stopRequest],
  )

  const updateShippingLine = useCallback(
    (shippingRateHandle) => {
      if (!cart) return

      // Do not do anything if a request is already in progress
      if (cart.cartRequest || !cart.checkout.id) return

      // First we flag that we are starting a request
      startRequest()

      const checkoutShippingLineUpdate = `
        mutation checkoutShippingLineUpdate($checkoutId: ID!, $shippingRateHandle: String!) {
          checkoutShippingLineUpdate(
            checkoutId: $checkoutId
            shippingRateHandle: $shippingRateHandle
          ) {
            checkout {
              id
            }
            checkoutUserErrors {
              code
              field
              message
            }
          }
        }
      `

      axios
        .post(
          'https://depalma-workwear.myshopify.com/api/2024-04/graphql.json',
          {
            query: checkoutShippingLineUpdate,
            variables: {
              checkoutId: cart.checkout.id,
              shippingRateHandle: `${shippingRateHandle}`,
            },
          },
          {
            headers: {
              'X-Shopify-Storefront-Access-Token':
                client.config.storefrontAccessToken,
            },
          },
        )
        .then((res) => {
          const { checkoutUserErrors } =
            res?.data?.data?.checkoutShippingLineUpdate || {}
          return client.checkout
            .fetch(cart.checkout.id)
            .then((res) =>
              checkoutUserErrors?.length
                ? { ...res, userErrors: checkoutUserErrors }
                : res,
            )
        })
        .then(saveCart)
        .catch(stopRequest)
    },
    [cart, client, saveCart, startRequest, stopRequest],
  )

  const getAvailableShippingRates = useCallback(() => {
    if (!cart || !country) return

    // Fetch all possible shippingRates form the country shippingZone if it is not already loaded
    if (!cart.data.shippingZone) {
      // Save an empty object so that no other request is initiated until this request has finished.
      // saveDataToCart({ shippingZone: {} });

      // Fetch the shippingZone from API
      fetch(
        `/.netlify/functions/getShippingZone?country=${country.slug.current}`,
      )
        .then((res) => res.json())
        .then((shippingZone) => saveDataToCart({ shippingZone: shippingZone }))
        .catch((error) => console.log(error))
    }

    const query = `
      query ($id: ID!) {
        node(id: $id) {
          ... on Checkout {
            email
            id
            webUrl
            availableShippingRates {
              shippingRates {
                handle
                priceV2 {
                  amount
                  currencyCode
                }
                title
              }
            }
          }
        }
      }
    `

    axios
      .post(
        'https://depalma-workwear.myshopify.com/api/2024-04/graphql.json',
        {
          query,
          variables: { id: cart.checkout.id },
        },
        {
          headers: {
            'X-Shopify-Storefront-Access-Token':
              client.config.storefrontAccessToken,
          },
        },
      )
      .then((res) =>
        saveDataToCart({
          availableShippingRates:
            res?.data?.data?.node?.availableShippingRates?.shippingRates,
        }),
      )
      .catch((err) => console.error(err))
  }, [cart, client, country, saveDataToCart])

  const addVariantToCart = useCallback(
    (product, externalReference, quantity) => {
      // Do not do anything if a request is already in progress
      if (cart?.cartRequest) return

      // First we flag that we are starting a request
      startRequest()

      // Build a new lineitem
      const lineItems = [
        {
          variantId: variantIdToGid(externalReference.variant_id),
          quantity,
        },
      ]

      getCheckoutId()
        .then((checkoutId) =>
          client.checkout
            .addLineItems(checkoutId, lineItems)
            .then(saveCart)
            .catch(stopRequest),
        )
        .catch(stopRequest)
    },
    [cart, client, saveCart, startRequest, stopRequest, getCheckoutId],
  )

  const removeLineItemInCart = useCallback(
    (lineItemId) => {
      if (!cart) return

      // Do not do anything if a request is already in progress
      if (cart.cartRequest) return

      // First we flag that we are starting a request
      startRequest()

      const newCart = {
        ...cart,
        checkout: {
          ...cart.checkout,
          lineItems: cart.checkout.lineItems.filter(
            (lineItem) => lineItem.id !== lineItemId,
          ),
        },
      }

      return client.checkout
        .removeLineItems(newCart.checkout.id, [lineItemId])
        .then((checkout) => {
          const updCart = { ...newCart, checkout, cartRequest: false }
          setCart(updCart)
          storeLocal(`${SHOPPING_CART_KEY}-${country.slug.current}`, updCart)
        })
        .catch(stopRequest)
    },
    [cart, client, country, saveCart, startRequest, stopRequest],
  )

  const updateLineItemInCart = useCallback(
    (lineItemId, updates) => {
      if (!cart) return

      // Do not do anything if a request is already in progress
      if (cart.cartRequest) return

      // First we flag that we are starting a request
      startRequest()

      client.checkout
        .updateLineItems(cart.checkout.id, [{ ...updates, id: lineItemId }])
        .then(saveCart)
        .catch(stopRequest)
    },
    [cart, client, saveCart, startRequest, stopRequest],
  )

  const applyDiscount = useCallback(
    (discountCode) => {
      if (!cart) return

      // Do not do anything if a request is already in progress
      if (cart.cartRequest) return

      // First we flag that we are starting a request
      startRequest()

      client.checkout
        .addDiscount(cart.checkout.id, discountCode)
        .then(saveCart)
        .catch(stopRequest)
    },
    [cart, client, saveCart, startRequest, stopRequest],
  )

  const removeDiscount = useCallback(() => {
    if (!cart) return

    // Do not do anything if a request is already in progress
    if (cart.cartRequest) return

    // First we flag that we are starting a request
    startRequest()

    client.checkout
      .removeDiscount(cart.checkout.id)
      .then(saveCart)
      .catch(stopRequest)
  }, [cart, client, saveCart, startRequest, stopRequest])

  const checkoutErrors = useMemo(() => {
    return (cart?.checkout?.userErrors || []).reduce((acc, userError) => {
      const fieldName = userError.field
        .map((f) => (typeof f === 'string' ? f : f.value))
        .join('.')

      return {
        ...acc,
        [fieldName]: acc[fieldName]
          ? [...acc[fieldName], userError.message]
          : [userError.message],
      }
    }, {})
  }, [cart])

  // Keeps track of separate carts for different countries.
  useEffect(() => {
    if (country && !cart) {
      const storedShoppingCart = getLocal(
        `${SHOPPING_CART_KEY}-${country.slug.current}`,
      )
      const initCart = { ...defaultCart, ...storedShoppingCart }
      setCart(initCart)
      // If the cart has not been saved in more than a minute, fetch the latest, just to make sure
      // it is not a completed checkout or that changes has not been made elsewhere.
      if (
        initCart.checkout?.id &&
        !initCart.cartRequest &&
        initCart.savedAt &&
        initCart.savedAt < Date.now() - 60 * 1000
      ) {
        client.checkout
          .fetch(initCart.checkout.id)
          .then((res) => {
            if (!res) {
              // The checkout does not exists anymore, reset it for now.
              // TODO: Copy any lineitems from previously cached checkout
              // into new checkout.
              setCart(defaultCart)
              storeLocal(
                `${SHOPPING_CART_KEY}-${country.slug.current}`,
                defaultCart,
              )
            } else if (res.order) {
              // If order is present, the checkout has been completed and we can reset the Cart.
              const {
                currencyCode,
                orderNumber,
                shippingDiscountAllocations = [],
                totalPriceV2,
                totalShippingPriceV2,
                totalTaxV2,
              } = res.order
              window.dataLayer = window.dataLayer || []
              try {
                window.dataLayer.push(function () {
                  this.reset()
                })
                window.dataLayer.push({
                  event: 'eec.purchase',
                  ecommerce: {
                    currencyCode,
                    purchase: {
                      actionField: {
                        id: orderNumber,
                        affiliation: 'depalmaworkwear.com',
                        revenue: totalPriceV2.amount,
                        tax: totalTaxV2.amount,
                        shipping: totalShippingPriceV2.amount,
                        coupon: shippingDiscountAllocations[0],
                      },
                      products: res.lineItems.map((lineItem) => ({
                        id: lineItem.variant.sku,
                        name: `${lineItem.title} / ${lineItem.variant.title}`,
                        quantity: lineItem.quantity,
                        price: lineItem.variant.priceV2.amount,
                        dimension2:
                          lineItem.variant.selectedOptions[0]?.value || '',
                        dimension3:
                          lineItem.variant.selectedOptions[1]?.value || '',
                      })),
                    },
                  },
                })
              } catch (e) {
                console.log(e)
              }

              setCart(defaultCart)
              storeLocal(
                `${SHOPPING_CART_KEY}-${country.slug.current}`,
                defaultCart,
              )
            } else {
              // Always save the cart otherwise which will update savedAt so we won't fetch it again
              // for 60 seconds.
              saveCart(res)
            }
          })
          .catch(stopRequest)
      }
    } else {
      //setCart(null);
    }
  }, [country, client, cart])

  const cartContext = {
    cart,
    client,

    addVariantToCart,
    applyDiscount,
    removeDiscount,
    checkoutErrors,
    getAvailableShippingRates,
    updateLineItemInCart,
    removeLineItemInCart,
    updateCart,
    updateContactDetails,
    updateShippingAddress,
    updateShippingLine,
    saveDataToCart,
  }

  return (
    <CartStateContext.Provider value={cartContext}>
      {children}
    </CartStateContext.Provider>
  )
}

export const useCartStateContext = () => {
  const context = useContext(CartStateContext)

  if (context === undefined) {
    throw new Error('useCartStateContext must be used within a CartProvider')
  }

  return context
}

export default withStoreContext(CartProvider)
