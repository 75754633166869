import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { getLocal, storeLocal, subscribeLocal, unsubscribeLocal } from "../utils/local";
import { withCountries } from "../queries";
import { useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

const CURRENT_COUNTRY_KEY = "DePalmaWorkwear-CurrentCountry";
const INCL_VAT_KEY = "DePalmaWorkwear-InclVAT";
const BEHAVIOUR_KEY = "DePalmaWorkwear-bhvr";

const CountryStateContext = createContext(null);

const CountryProvider = props => {
  const {
    allSanityCountry: { edges: countryEdges = [] },
    children,
  } = props;

  const listOfCountries = useMemo(() => countryEdges.map(c => c.node), [countryEdges]);
  const { site } = useCMSContext();

  // Main context state variable
  const [state, setState] = useState({});
  const [includeVAT, setIncludeVATState] = useState(true);
  const [behaviour, setBehaviourState] = useState(true);

  // One-time Effect hook to load the state oject from local storage (if present)
  // and to subscribe to changes from other tabs
  useEffect(() => {
    const localState = getLocal(CURRENT_COUNTRY_KEY);
    localState && setState(localState);

    const localIncludeVAT = getLocal(INCL_VAT_KEY, false);
    localIncludeVAT && setIncludeVATState(localIncludeVAT === 'true');

    const localBehaviour = getLocal(BEHAVIOUR_KEY);
    localBehaviour && setBehaviourState(localBehaviour);

    subscribeLocal(CURRENT_COUNTRY_KEY, setState);
    subscribeLocal(INCL_VAT_KEY, setIncludeVATState, false);
    subscribeLocal(BEHAVIOUR_KEY, setBehaviourState);

    return () => {
      unsubscribeLocal(CURRENT_COUNTRY_KEY, setState);
      unsubscribeLocal(INCL_VAT_KEY, setIncludeVATState);
      unsubscribeLocal(BEHAVIOUR_KEY, setBehaviourState);
    };
  }, []);

  // Cache a list of possible countries for the current site
  const possibleCountries = useMemo(() => {
    return listOfCountries.reduce((acc, country) => {
      if (country.site._id === site._id) {
        return { ...acc, [country.slug.current]: country };
      }
      return acc;
    }, {});
  }, [site, listOfCountries]);

  // Cache the selected country based on state. If nothing is set, it
  // can default to a country if that is the only country for the
  // current site.
  const country = useMemo(() => {
    if (state[site._id]) {
      return possibleCountries[state[site._id]];
    } else if (Object.values(possibleCountries).length === 1) {
      return Object.values(possibleCountries)[0];
    }
    return null;
  }, [possibleCountries, state, site]);

  // Update the local storage and set local state. Subscription to changes
  // in local storage does not trigger when updated from the same window/tab.
  const setCountry = useCallback((forSite, newCountry, siteAndLangPrefix) => {
    const updState = { ...state, [forSite._id]: newCountry.slug.current, lastSelectedSitePrefix: siteAndLangPrefix };
    storeLocal(CURRENT_COUNTRY_KEY, updState);
    setState(updState);
  }, [state]);

  const setIncludeVAT = useCallback(newIncludeVAT => {
    if (newIncludeVAT === null)
      return;

    const newValue = typeof newIncludeVAT === 'string' ? newIncludeVAT === 'true' : newIncludeVAT;
    storeLocal(INCL_VAT_KEY, newValue);
    setIncludeVATState(newValue);
  }, []);

  const setBehaviour = useCallback((behaviourChanges) => {
    const updBehaviour = { ...behaviour, ...behaviourChanges };
    setBehaviourState(updBehaviour);
    storeLocal(BEHAVIOUR_KEY, updBehaviour);
  }, [behaviour]);

  // Final context object
  const countryContext = {
    country,
    lastSelectedSitePrefix: state.lastSelectedSitePrefix,
    setCountry,
    behaviour,
    setBehaviour,
    includeVAT,
    setIncludeVAT,
    currencyCode: country?.currencyCode || site.defaultCurrencyCode,
  };

  return (
    <CountryStateContext.Provider value={countryContext}>
      {children}
    </CountryStateContext.Provider>
  );
};

export const useCountryStateContext = () => {
  const context = useContext(CountryStateContext);

  if (context === undefined) {
    throw new Error("useCountryStateContext must be used within a CountryProvider");
  }

  return context;
};

export default withCountries(CountryProvider);
