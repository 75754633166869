import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import Price from "../../components/Price";
import { useCartStateContext } from "../../contexts/CartContext";
import { useCountryStateContext } from "../../contexts/CountryContext";

import PromoCode from "./PromoCode";


const CartVAT = props => {
  const {
    cart,
    country,
    includeVAT,
    totalTaxV2,
    totalPriceV2,
  } = props;
  
  // const swedishVAT = 0.25;
  // const vatRate = country?.region?.name?.en === 'Europe' ? swedishVAT : country?.vatRate || 0;
  const vatRate = country?.vatRate || 0;

  const renderPrice = () => {
    if (cart?.checkout?.shippingAddress)
      return <Price {...totalTaxV2} />;

    if (!country || !totalPriceV2)
      return '-';

    const amount = totalPriceV2.amount - (totalPriceV2.amount / (1 + vatRate));
    return <Price amount={amount} currencyCode={totalPriceV2.currencyCode} />;
  };

  return (
    <div className="flex justify-between">
      <h3 className="averta-bold">
        <FormattedMessage
          id="shoppingCart.vat"
          defaultMessage="VAT ({vatRate}%)"
          values={{
            vatRate: (vatRate || 0) * 100,
          }}
        />
      </h3>
      <div className="text-right averta-bold">
        {renderPrice()}
      </div>
    </div>
  );
};


const CartTotals = props => {
  const { cart } = useCartStateContext();
  const { country, includeVAT } = useCountryStateContext();

  const {
    shippingLine,
    lineItemsSubtotalPrice,
    subtotalPriceV2,
    totalPriceV2,
    totalTaxV2,
  } = cart?.checkout || {};

  const renderShippingLine = useCallback(priceV2 => {
    if (parseFloat(priceV2.amount) === 0) {
      return (
        <FormattedMessage
          id="checkout.free"
          defaultMessage="Free"
        />
      );
    } else {
      return <Price {...shippingLine.priceV2} />;
    }
  });

  return (
    <div className="text-sm">
      <div className="flex justify-between mb-6">
        <h3 className="averta-bold">
          <FormattedMessage
            id="shoppingCart.subtotal"
            defaultMessage="Subtotal"
          />
        </h3>
        <div className="text-right averta-bold">
          {lineItemsSubtotalPrice ? (
            // <Price
            //   amount={includeVAT ? lineItemsSubtotalPrice.amount : lineItemsSubtotalPrice.amount - totalTaxV2.amount}
            //   currencyCode={lineItemsSubtotalPrice.currencyCode}
            // />
            <Price
              amount={lineItemsSubtotalPrice.amount}
              currencyCode={lineItemsSubtotalPrice.currencyCode}
              recalculateForVAT={true}
            />
          ) : '-'}
        </div>
      </div>

      <div className="mb-6">
        <PromoCode includeVAT={includeVAT} />
      </div>

      <div className="flex justify-between mb-6">
        <h3 className="averta-bold">
          <FormattedMessage
            id="shoppingCart.estimated-freight"
            defaultMessage="Estimated Freight"
          />
        </h3>
        <div className="text-right averta-bold">
          {shippingLine ? renderShippingLine(shippingLine.priceV2) : '-'}
        </div>
      </div>

      <div className="mb-6">
        <CartVAT
          cart={cart}
          totalTaxV2={totalTaxV2}
          totalPriceV2={totalPriceV2}
          country={country}
          includeVAT={includeVAT}
        />
      </div>

      <div className="flex justify-between mb-6">
        <h3 className="averta-bold">
          <FormattedMessage
            id="shoppingCart.total"
            defaultMessage="Total"
          />
        </h3>
        <div className="text-right averta-bold">
          {totalPriceV2 ? <Price {...totalPriceV2} /> : '-'}
        </div>
      </div>
    </div>
  );
};

export default CartTotals;
