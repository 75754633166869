import React from "react";
import { graphql, StaticQuery } from 'gatsby';

const withDiscontinuedProducts = (DecoratedComponent) => {
  return props =>  (
    <StaticQuery
      query={graphql`
        query  {
          allSanityProductPageVariant (filter: { discontinued: { eq: true }, hidden: { ne: true } }) {
            edges {
              node {
                ...SanityProductPageVariantPreviewFragment
              }
            }
          }
        }
      `}
      render={data => <DecoratedComponent {...data} {...props} />}
    />
  );
};

export default withDiscontinuedProducts;
