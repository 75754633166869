import React from "react";
import { graphql, StaticQuery } from 'gatsby';

import * as fragments from "../nodes/sanity/graphqlFragments";

const withSites = (DecoratedComponent) => {
  return props =>  (
    <StaticQuery
      query={graphql`
        query SiteQuery {
          allSanitySite {
            edges {
              node {
                _id
                _type

                defaultCurrencyCode
                languages {
                  en
                  sv
                }
                shopifyShopName
                slug {
                  ...SanitySlugFragment
                }
              }
            }
          }
        }
      `}
      render={data => <DecoratedComponent {...data} {...props} />}
    />
  );
};

export default withSites;
