import React from "react";
import { graphql, StaticQuery } from 'gatsby';

const withCountries = (DecoratedComponent) => {
  return props =>  (
    <StaticQuery
      query={graphql`
        query CountryQuery {
          allSanityCountry (filter: {site: {_type: {eq: "site"}}}) {
            edges {
              node {
                _id
                _type
  
                currencyCode
                defaultLanguage
                defectiveProductPolicy
                languages {
                  en
                  sv
                }
                name {
                  ...SanityLocaleStringFragment
                }
                paymentMethods {
                  ...SanityPaymentMethodsFragment
                }
                region {
                  _id
                  _type
                  name {
                    ...SanityLocaleStringFragment
                  }
                }
                shippingReturnPolicy
                site {
                  _id
                }
                slug {
                  current
                }
                sortBy
                sovereignity
                vatRate
              }
            }
          }
        }
      `}
      render={data => <DecoratedComponent {...data} {...props} />}
    />
  );
};

export default withCountries;
