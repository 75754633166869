import React from "react";
import { graphql, StaticQuery } from 'gatsby';

const withNewProducts = (DecoratedComponent) => {
  return props =>  (
    <StaticQuery
      query={graphql`
        query  {
          allSanityProductPageVariant (filter: { discontinued: { ne: true }, newProduct: { eq: true }, hidden: { ne: true } }) {
            edges {
              node {
                ...SanityProductPageVariantPreviewFragment
                newProduct
              }
            }
          }
        }
      `}
      render={data => <DecoratedComponent {...data} {...props} />}
    />
  )
};

export default withNewProducts;
