"use strict";

exports.__esModule = true;
exports.default = void 0;
var _CMSContext = require("../../contexts/CMSContext");
var _index = require("./index");
var String = function String(props) {
  var _props$placeHolder = props.placeHolder,
    placeHolder = _props$placeHolder === void 0 ? null : _props$placeHolder,
    _props$lang = props.lang,
    lang = _props$lang === void 0 ? null : _props$lang,
    value = props.value;
  var _useCMSContext = (0, _CMSContext.useCMSContext)(),
    currentLang = _useCMSContext.lang;
  var targetLang = lang || currentLang;
  var localeValue = (0, _index.getLocaleValue)(value, targetLang);
  if (!localeValue) return placeHolder;
  return localeValue;
};
var _default = exports.default = String;