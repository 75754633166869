import React from "react";
import { components as selectComponents } from "react-select";
import FieldSelect from ".";

import { getLocaleValue, LocaleString } from "@happy-rabbit/gatsby-sanity-cms";

import "flag-icon-css/css/flag-icons.min.css";

const { Option, SingleValue } = selectComponents;

export const buildCountryOption = (country, lang) => ({
  label: getLocaleValue(country.name, lang),
  value: country.slug.current,
  country,
  matchOn: Object.keys(country.name).reduce((acc, k) => k[0] !== '_' && country.name[k] ? [...acc, country.name[k].toLowerCase()] : acc, []).join(' '),
});

// const filterOption = (candidate, input) => {
//   console.log(candidate.data, candidate.data.matchOn, input)
//   return candidate.data.matchOn.includes(input.toLowerCase());
// };

const IconOption = ({ data: { country, ...data }, ...props }) => {
  return (
    <Option {...props} {...{ data }}>
      <span className="flex">
        <span className={`mr-1 flag-icon flag-icon-${country.slug.current}`}/>
        <LocaleString value={country.name} />
      </span>
    </Option>
  );
};

const IconSingleValue = ({ data: { country, ...data }, ...props }) => {
  return (
    <SingleValue {...props} {...{ data }}>
      <span className="flex">
        <span className={`mr-1 flag-icon flag-icon-${country.slug.current}`}/>
        <LocaleString value={country.name} />
      </span>
    </SingleValue>
  );
};

const CountrySelect = props => (
  <FieldSelect
    {...props}
    // filterOption={filterOption}
    components={{ Option: IconOption, SingleValue: IconSingleValue }}
  />
);

export default CountrySelect;
