import React, { useCallback } from "react";

import { PageLink } from "@happy-rabbit/gatsby-sanity-cms/components";

const itemMap = {
  "navItems.page": PageLink,
};

const Menu = props => {
  const {
    containerClassName = "",
    headerClassName = "mb-4 averta-bold",
    includeTitle = false,
    itemClassName = "mb-2 block",
    lang,
    nav,
    site,
  } = props;

  const renderMenuItem = useCallback(navItem => {
    const Comp = itemMap[navItem._type];

    if (!Comp)
      return null;

    return (
      <div key={navItem._key} className={itemClassName}>
        <Comp
          lang={lang}
          pageConfig={navItem.navItemPage?.pageConfig}
          site={site}
          title={navItem.title[lang]}
        />
      </div>
    );
  }, [itemClassName, lang, site]);

  return (
    <div className={containerClassName}>
      {includeTitle ? <h2 className={headerClassName}>{nav.title[lang]}</h2> : null}

      {nav.navItems.map(renderMenuItem)}
    </div>
  );
};

export default Menu;
