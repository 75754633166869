import { graphql, useStaticQuery } from 'gatsby';

export const useMetadata = () => {
  const { site: { siteMetadata } } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultDescription: description
          defaultImage: image
          defaultKeywords: keywords
          defaultTitle: title
          siteUrl
          titleTemplate
        }
      }
    }
  `);
  return siteMetadata || {};
};
