"use strict";

exports.__esModule = true;
var _swiper = _interopRequireWildcard(require("swiper"));
var _react = require("swiper/react");
exports.Swiper = _react.Swiper;
exports.SwiperSlide = _react.SwiperSlide;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
// import Swiper core and required modules

// install Swiper modules
_swiper.default.use([_swiper.Navigation, _swiper.Pagination, _swiper.Scrollbar, _swiper.A11y, _swiper.Thumbs]);