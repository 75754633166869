export function storeLocal(key, val, object = true) {
  if (typeof(Storage) !== "undefined") {
    localStorage.setItem(key, object ? JSON.stringify(val) : val);
  }  
}

export function getLocal(key, object = true) {
  if (typeof Storage === "undefined")
    return null;

  const val = localStorage.getItem(key);
  if (val) {
    try {
      return object ? JSON.parse(val) : val;
    } catch(e) {
      return null;
    }
  }
  return null;
}

export function removeLocal(key) {
  if (typeof Storage === "undefined")
    return null;

  localStorage.removeItem(key);
}

const subscriptions = {
  callbacks: [],
  listener: null,
};

function listen() {
  if (!subscriptions.listener) {
    subscriptions.listener = window.addEventListener("storage", ({ key, newValue }) => {
      subscriptions.callbacks.forEach(([subscribeToKey, callback, object]) => {
        if (subscribeToKey === key) {
          callback(object ? JSON.parse(newValue) : newValue);
        }
      });
    });
  }
}

export function subscribeLocal(key, callback, object = true) {
  if (typeof(Storage) !== "undefined") {
    subscriptions.callbacks.push([key, callback, object]);
    listen();
  }
}

export function unsubscribeLocal(key, callback) {
  if (typeof(Storage) !== "undefined") {
    subscriptions.callbacks = subscriptions.callbacks.filter(([subscribeToKey, cb]) => !(subscribeToKey === key && callback === cb));
  }
}
