import { Cookies } from "react-cookie-consent";

export const SAME_SITE_OPTIONS = {
  STRICT: "strict",
  LAX: "lax",
  NONE: "none",
};

const getLegacyCookieName = (name) => {
  return `${name}-legacy`;
};

export function setCookie(cookieName, cookieValue, options = {}) {
  const {
    extraCookieOptions = {},
    expires = 365,
    sameSite = SAME_SITE_OPTIONS.LAX,
    cookieSecurity = location ? location.protocol === "https:" : true,
  } = options;

  let cookieOptions = { expires, ...extraCookieOptions, sameSite, secure: cookieSecurity };

  // Fallback for older browsers where can not set SameSite=None, SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
  if (sameSite === SAME_SITE_OPTIONS.NONE) {
    Cookies.set(getLegacyCookieName(cookieName), cookieValue, cookieOptions);
  }

  // set the regular cookie
  Cookies.set(cookieName, cookieValue, cookieOptions);
}
