/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import { urlFor } from "../../utils/sanity";
import { getLocaleValue, useCMSContext } from "@happy-rabbit/gatsby-sanity-cms";

function pathFor(site, lang, slug, pathname) {
  if (slug) {
    if (site._id === "global") {
      return slug.current;
    } else {
      const pathPrefix = `/${site.slug.current}/${lang}`;
      return `${pathPrefix}${slug.current}`;
    }
  } else {
    return pathname;
  }
}

const SEO = ({ pageFields }) => {
  const { pathname } = useLocation();

  const { pageConfig, lang, site, siteMetadata } = useCMSContext();
  const { pageSeo, slug } = pageConfig || {};
  const {
    description: pageDescription,
    image: pageImage,
    title: pageTitle,
    titleTemplate: pageTitleTemplate,
  } = pageFields || {};

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultKeywords,
    siteUrl,
    defaultImage,
  } = siteMetadata;

  const { description: seoDescription, image: seoImage, title: seoTitle } =
    pageSeo || {};

  // Take the image from pageSEO if present, otherwise the image given from the page
  // tempate used (if any). Lastyl defaults to the static logo.
  const image = seoImage || pageImage || defaultImage;
  const metaImage =
    typeof image === "object"
      ? {
          src: urlFor(image).width(1200).height(630).url(),
          alt: image.altText,
          width: 1200,
          height: 630,
          twitterSrc: urlFor(image).width(1200).height(1200).url(),
        }
      : {
          src: `${siteUrl}${image}`,
          alt: "The DePalma Workwear Logo",
          width: 225,
          height: 225,
        };

  const seo = {
    description:
      getLocaleValue(seoDescription, lang) ||
      getLocaleValue(pageDescription, lang) ||
      defaultDescription,
    keywords: defaultKeywords,
    title:
      getLocaleValue(seoTitle, lang) ||
      getLocaleValue(pageTitle, lang) ||
      defaultTitle,
    titleTemplate: pageTitleTemplate || titleTemplate,
    url: slug ? `${siteUrl}${pathFor(site, lang, slug, pathname)}` : siteUrl,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      htmlAttributes={{ lang }}
      link={[
        {
          rel: "canonical",
          href: seo.url,
        },
      ]}
      meta={[
        {
          name: "description",
          content: seo.description,
        },
        // {
        //   name: 'keywords',
        //   content: seo.keywords.join(','),
        // },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "og:url",
          content: seo.url,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
      ].concat(
        metaImage
          ? [
              {
                property: "og:image",
                content: metaImage.src,
              },
              ...(metaImage.alt
                ? [
                    {
                      property: "og:image:alt",
                      content: metaImage.alt,
                    },
                  ]
                : []),
              {
                property: "og:image:width",
                content: metaImage.width,
              },
              {
                property: "og:image:height",
                content: metaImage.height,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
              {
                name: "twitter:image",
                content: metaImage.twitterSrc || metaImage.src,
              },
            ]
          : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
      )}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "WebSite",
          url: seo.url,
          name: seo.title,
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "DePalma Workwear",
          sameAs: [
            "https://www.instagram.com/depalmaworkwear/",
            "https://www.facebook.com/depalmaworkwear/",
            "https://www.linkedin.com/company/depalma-workwear/",
          ],
          url: "https://depalmaworkwear.com",
        })}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

export default SEO;
